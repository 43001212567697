@import "./constants";

.UpgradePage {
  font-family: $primary_font_family;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 0;
  }

  &__tab-nav-header {
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: #ffffff;
    padding: 40px 15px;
    padding-bottom: 30px;

    & > h1 {
      margin-top: 0;
      margin-bottom: 15px;
      font-weight: 600;
      letter-spacing: -1px;
      font-size: 32px;
    }

    @media (min-width: 768px) {
      & > h1 {
        margin-top: 20px;
        font-size: 54px;
        letter-spacing: -3px;
      }
    }
  }

  &__upgrade-cta {
    color: #263aec;
    font-weight: 600;
    font-size: 16px;
    text-align: center;

    a {
      color: #263aec;
    }
  }

  &__callout {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
    justify-content: center;

    a {
      color: #333333;

      &:hover {
        text-decoration: underline;
      }
    }

    button {
      appearance: none;
      background: none;
      border: 0;
    }
  }

  &__tab-nav-header-background {
    background: $purple;
    width: 100vw;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
  }

  &__heading {
    font-size: 40px;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;

    &-jumbo {
      font-weight: 600;
      text-align: center;
      font-size: 24px;

      @media (max-width: map-get($grid-breakpoints, "md")) {
        font-size: 40px;
      }
    }
  }

  .faq__header {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: map-get($grid-breakpoints, "md")) {
      flex-direction: column;
    }

    .UpgradePage__heading {
      flex: 1;
      display: flex;
      text-align: left;
    }
  }

  &__subheading {
    text-align: center;
    font-size: 16px;
    font-weight: medium;
    display: block;
  }

  &__heading-text {
    color: #8a8a8e;
    text-align: center;
  }

  &__prices {
    margin-top: 32px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

    @media (max-width: map-get($grid-breakpoints, "lg")) {
      flex-wrap: wrap;
    }

    &--single {
      align-items: center;
    }
  }

  &__upsell {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    color: #9b9b9b;
    font-size: 20px;
  }

  &__testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__course {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px 30px 30px;
    align-items: center;
    justify-content: center;

    // TODO: move these styles elsewhere
    .Button {
      min-width: 300px;
    }
  }

  &__grid {
    &-item {
      padding: 15px 40px;

      @media (max-width: map-get($grid-breakpoints, "md")) {
        padding: 5px 40px;
        text-align: center;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 20px;
    }

    &-text {
      color: #8a8a8e;
      font-size: 16px;
    }
  }

  &__course-inner {
    margin: 0 auto;
  }

  & &__secondary-link,
  & &__try-text {
    margin-top: 20px;
    color: #9b9b9b;
    font-size: 18px;
  }

  &__coaches-list {
    padding-top: 40px;
  }
}
