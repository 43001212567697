@import './constants';

$ctaColor: #263aec;
$ctaColorDisabled: #999999;

s,
strike {
  text-decoration: none;
  position: relative;
}

s::before,
strike::before {
  top: 50%; /* tweak this to adjust the vertical position if it's off a bit due to your font family */
  background: #a9a9a9; /* this is the color of the line */
  opacity: 0.7;
  content: '';
  width: 110%;
  position: absolute;
  height: 0.1em;
  border-radius: 0.1em;
  left: -5%;
  white-space: nowrap;
  display: block;
  transform: rotate(-15deg);
}

s.straight::before,
strike.straight::before {
  transform: rotate(0deg);
  left: -1%;
  width: 102%;
}

.PlanCard {
  font-family: $primary_font_family;
  box-shadow: $shadow-lg;
  border-radius: $border-lg;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  flex-basis: 350px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    color: black;
  }

  &__badge {
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: $border-lg;
    padding: 0 14px;
  }

  &__pricing {
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
    padding: 0 4px;

    &::first-letter {
      /* Makes the dollar sign smaller */
      font-size: 20px;
    }
  }

  &__price-secondary {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-left: 6px;
    color: #888888;
  }

  &__price-discount {
    font-size: 32px;
    font-weight: 700;
    line-height: 50px;
    padding: 0 4px;
    color: #a9a9a9;
  }

  &__features {
    list-style-type: none;
    margin: 0;
    padding: 6px;
  }

  &__feature {
    line-height: 32px;
    font-weight: 500;

    svg {
      margin-right: 12px;
      position: relative;
      top: 1px;
    }
  }

  &__button {
    margin: 24px -6px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 48px;
    color: $ctaColor;
    border: 2px solid $ctaColor;
    border-radius: $border-lg;
    background: transparent;
    padding: 0;

    &--primary {
      border: 0;
      background-color: $ctaColor;
      color: #ffffff;
    }

    &--disabled {
      color: $ctaColorDisabled;
      border: 2px solid $ctaColorDisabled;
      background: transparent;
    }
  }

  &--highlighted {
    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      // On larger screens, lift the featured card vertically
      position: relative;
      top: -30px;
      bottom: 0;
    }
  }
}
